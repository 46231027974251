<template>
  <div>
    <DataTableComponent :table="table"></DataTableComponent>
  </div>
</template>

<script>
import DataTableComponent from "../../components/DataTableComponent";

export default {
  name: "CashPoints",
  components: { DataTableComponent },
  data() {
    return {
      table: {
        modal: "Cash Point",
        path: "cashpoints",
        headers: [
          {
            text: this.$i18n.t("Code"),
            value: "code",
            sortable: false,
          },
          {
            text: this.$i18n.t("Name"),
            value: "en.name",
          },
          {
            text: this.$i18n.t("Arabic Name"),
            value: "ar.name",
          },
          {
            text: this.$i18n.t("Currency $$"),
            value: "currency_text",
            sortable: false,
          },
          {
            text: this.$i18n.t("Type"),
            value: "type_text",
          },
          {
            text: this.$i18n.t("Status"),
            value: "active",
          },
          {
            text: this.$i18n.t("Payment Method"),
            value: "payment_method_text",
          },
          {
            text: this.$i18n.t("Online Payment"),
            value: "OnlinePayment",
            sortable: false,
          },

          {
            text: this.$i18n.t("Actions"),
            value: "actions",
            sortable: false,
            width: "3%",
          },
        ],
        title: this.$i18n.t("Cash Points"),
        apiEndPoints: {
          list: "CashPoints",
          create: "CashPoints/store",
          edit: "CashPoints/update",
          delete: "CashPoints/destroy",
        },
        editedItem: {
          id: "",
          name: "",
          type: "",
          code: "",
          currency: "SAR",
          payment_method: [],
          active: "",
          users: [],
          en: {
            name: "",
          },
          ar: {
            name: "",
          },
        },
        permissions: {
          list: "list-cash-points",
          add: "add-cash-points",
          edit: "edit-cash-points",
          delete: "delete-cash-points",
        },
        filter: {
          type: [],
          payment_method: "",
        },
      },
    };
  },
};
</script>
